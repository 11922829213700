.jumbotron {
  background: url(../assets/images/background.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  z-index: 1;
  padding-bottom: 0;
  background-attachment: fixed;
  background-position: right;
  .overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
    .key-message {
      margin-top: 50vh;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      background: rgba(#222222, 0.4);
      padding: 50px;
      h1 {
        color: $white;
        text-align: center;
        font-size: 6em;
        font-weight: 200;
        text-shadow: 4px 4px $black;
        padding-top: 0;
        font-family: cursive;
        .wf-active & {
          font-family: "Parisienne", cursive;
        }
      }
      h2 {
        color: $white;
        text-align: center;
        font-size: 5em;
        font-weight: 200;
        font-family: serif;
        .wf-active & {
          font-family: "Cormorant", serif;
        }
      }
      p {
        color: $white;
        text-align: center;
        font-size: 4em;
        .btn-primary {
          background: none;
          border: $white solid 2px;
          border-radius: 0;
          transition: 0.5s all;
          cursor: pointer;
        }
        .btn-primary:hover {
          background: $white;
          color: $black;
        }
      }
      @media only screen and (max-width: 600px) {
        h1 {
          font-size: 2.5em;
        }
        h2 {
          font-size: 2.5em;
        }
        p {
          font-size: 1.5em;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .jumbotron {
    background-attachment: initial;
  }
}
