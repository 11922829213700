#what {
    h2 {
        font-size: 3em;
        font-size: 2.5em;
        .wf-active & {
            font-family: 'Cormorant', serif;
            font-weight: bold;
        }
    }
    p {
        font-size: 2em;
        text-transform: uppercase;
    }
    .highlight {
        text-transform: initial;
        font-family: cursive;
        font-size: 2.5em;
        .wf-active & {
            font-family: 'Parisienne', cursive;
        }
    }
}