#when {
    .countdown {
        font-size: 3em;
        display: block;
        text-align: center;
        width: 100%;
        margin: 25px 0 100px 0;
        .number {
            background: $black;
            color: $white;
            padding: 10px;
            font-size: 1.5em;
            border-radius: 10px;
        }
    }
    h2 {
        font-size: 5em;
        text-align: center;
        margin-top: 0;
        margin: 25px 0;
    }
    .lg-icon {
        text-align: center;
        width: 100%;
        font-size: 10em;
        margin: 25px 0;
    }
    @media only screen and (max-width: 500px) {
        h2 {
            font-size: 3em;
        }
        .countdown {
            font-size: 1.5em;
            text-align: left;
        }
        .line {
            clear: both;
            width: 100%;
            float: left;
            margin: 10px 0;
        }
    }
}