form {
    .reaction {
        margin-left: 20px;
        background: $purple;
        padding: 5px 20px;
        color: $white;
        transition: all 0.2s;
        opacity: 1;
        border-radius: 5px;
    }
    .ng-hide {
        opacity: 0;
    }
    label,
    input {
        font-size: 1.3em;
    }
}