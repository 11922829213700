/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */

$navbar-inverse-link-color: #fff;
$icon-font-path: "../../bower_components/bootstrap-sass/assets/fonts/bootstrap/";

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */

// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
$black: #000000;
$green: #77BA99;
$yellow: #EFF0D1;
$pink: #D7C0D0;
$red: #D33F49;
$white: #FFF;
$purple: #663399;
body {
    color: $black;
    position: relative;
    font-family: serif;
    .wf-active & {
        font-family: 'Cormorant', serif;
    }
}

p {
    font-size: 1.2em;
}

section {
    padding: 50px 0;
}

.photo {
    background: url(../assets/images/background.jpg);
    background-size: cover;
    height: 50vh;
    width: 100%;
    z-index: 1;
    padding-bottom: 0;
    background-attachment: fixed;
}

@media only screen and (max-width: 900px) {
    .photo {
        background-attachment: initial;
    }
}


/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */

// injector
@import "components/jumbotron/jumbotron.scss";
@import "components/about/about.scss";
@import "components/map/map.scss";
@import "components/when/when.scss";
@import "components/navbar/navbar.scss";
@import "components/rsvp/rsvp.scss";
// endinjector